import axios from 'axios';
import { userStore } from '@/stores/user';
import { Roles } from '@/interfaces/roles';

export async function isAuthenticated(): Promise<boolean> {
  try {
    const response = await axios.get('/api/auth/authorized');
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export async function atleastRequiredRank(role: string): Promise<boolean> {
  try {
    const userRole = await axios.get('/api/user/store-user');

    const rolesHierarchy = ['snack-admin', 'admin', 'service-admin'];

    const requiredRoleIndex = rolesHierarchy.indexOf(role);
    const userRoleIndex = rolesHierarchy.indexOf(userRole.data.role);

    return requiredRoleIndex <= userRoleIndex;
  } catch (e) {
    console.error(e);
    return false;
  }
}
