<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import Navbar from './components/NavbarComponent.vue';

const route = useRoute();

const home = ref({
  path: '/'
});

const routes = computed(() => {
  return route.matched.map((r) => ({
    name: r.name,
    ...(r.children && r.children.length ? {} : { path: r.path })
  }));
});

const excludedRoutes: string[] = ['login', 'monitor'];

const isExcludedRoute = computed(() => {
  return excludedRoutes.includes(route.name as string);
});
</script>

<template>
  <div class="full-app">
    <Navbar v-if="!isExcludedRoute"></Navbar>
    <RouterView />
  </div>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
}

#krumbein-breadcrumps {
  background-color: rgb(247, 247, 247);
}

footer {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.full-app {
  position: relative;
  min-height: 100vh; /* Mindestens die volle Höhe des Viewports */
  display: flex;
  flex-direction: column; /* Um Footer nach unten zu schieben */
}

#app {
  min-height: 100vh;
}
</style>
