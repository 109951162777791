import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import axios from 'axios';
import './assets/main.css';
import Toast from 'primevue/toast';
import './assets/project-layer.css';
import Tooltip from 'primevue/tooltip';
import 'primeicons/primeicons.css';
import './registerServiceWorker';

const app = createApp(App);

app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: 'white',
      cssLayer: {
        name: 'primevue',
        order: 'primeui, primevue, krum'
      }
    }
  }
});
app.use(ToastService);
app.component('Toast', Toast);
app.directive('tooltip', Tooltip);

axios.create({
  baseURL: `${window.location.origin}`,
  withCredentials: true
});

app.mount('#app');
