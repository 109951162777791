<script setup lang="ts">
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import type { PictureResponse } from '@/interfaces/picture';
import ProgressSpinner from 'primevue/progressspinner';

const { t } = useI18n();

const emit = defineEmits(['onSelect']);
const pictures = ref<PictureResponse[]>([]);

const spinner = ref(false);

const fetchPictures = async (): Promise<void> => {
  try {
    spinner.value = true;
    const response = await axios.get('/api/recipe/pictures');
    const uniquePictures: PictureResponse[] = [];

    const seenNames = new Set<string>();

    for (const picture of response.data) {
      if (!seenNames.has(picture.title)) {
        seenNames.add(picture.title);
        uniquePictures.push(picture);
      }
    }

    pictures.value = uniquePictures;
    spinner.value = false;
  } catch (error) {
    spinner.value = false;
    console.error('Fehler beim Abrufen der Bilder:', error);
  }
};

const selectPicture = (picture: string) => {
  emit('onSelect', picture);
};

onMounted(async () => {
  await fetchPictures();
});
</script>

<template>
  <div v-if="spinner" class="spinner">
    <ProgressSpinner :style="{ color: 'blue' }" />
  </div>
  <div class="masonry-container">
    <div class="masonry-item" v-for="step in pictures" :key="step.id">
      <div class="row">
        <div class="col-md-4">
          <img :src="step.image" :alt="step.title" class="img-fluid" />
        </div>
        <div class="col-md-8">
          <div class="content">
            <h3>{{ step.title }}</h3>
            <button @click="selectPicture(step.image)">
              {{ t('admin.dashboard.choose') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.masonry-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
}

.masonry-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

.row {
  display: flex;
  flex-direction: column;
  text-align: center;
}

img {
  max-width: 100%;
  height: 200px;
  object-fit: cover;
}

.content {
  padding: 16px;

  h3 {
    font-size: 1.2em;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

button {
  background-color: var(--light-blue-color);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--dark-blue-color);
    border-color: var(--p-button-primary-hover-border-color);
  }

  &:active {
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.3);
    background: var(--p-button-primary-active-background);
    border-color: var(--p-button-primary-active-border-color);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
