import { createI18n } from 'vue-i18n';
import de from './locales/de';
import gb from './locales/gb';

const messages = { de, gb };

export default createI18n({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'gb',
  messages
});
