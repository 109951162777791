<script setup lang="ts">
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import { ref } from 'vue';
import { userStore } from '../stores/user';
import { Roles } from '../interfaces/roles';
import { useI18n } from 'vue-i18n';

const visible = ref(false);

const { t } = useI18n();
const store = userStore();
</script>

<template>
  <div class="card flex justify-content-center">
    <Sidebar v-model:visible="visible">
      <template #container="{ closeCallback }">
        <div>
          <div class="sidebar-header">
            <span>
              <img
                src="../assets/sidebar_logo.png"
                alt="logo"
                style="width: 25%"
              />
            </span>
            <span>
              <Button
                type="button"
                severity="secondary"
                @click="closeCallback"
                icon="pi pi-times"
                rounded
                text
              ></Button>
            </span>
          </div>
          <div class="sidebar-items">
            <ul>
              <router-link class="link" to="/">
                <li class="sidebar-item" @click="visible = false">
                  <i class="pi pi-home mr-2"></i>
                  <span class="font-medium">{{
                    t('admin.sidebarComponent.dashbaord')
                  }}</span>
                </li>
              </router-link>
              <router-link to="/administration" class="link">
                <li
                  class="sidebar-item"
                  v-if="store.user.role !== Roles.SNACK_ADMIN"
                  @click="visible = false"
                >
                  <i class="pi pi-address-book mr-2"></i>
                  <span class="font-medium">{{
                    t('admin.sidebarComponent.administraion')
                  }}</span>
                </li>
              </router-link>
              <router-link to="/monitor/configuration" class="link">
                <li class="sidebar-item" @click="visible = false">
                  <i class="pi pi-desktop mr-2"></i>
                  <span class="font-medium">{{
                    t('admin.sidebarComponent.monitor')
                  }}</span>
                </li>
              </router-link>
            </ul>
          </div>
        </div>
      </template>
    </Sidebar>
    <Button
      class="hov"
      icon="pi pi-bars"
      @click="visible = true"
      rounded
      text
      style="color: #fff"
    />
  </div>
</template>

<style scoped>
.sidebar-header {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
}

.sidebar-item {
  display: flex;
  width: 100%;
  color: black;
  align-items: center;
  padding: 10px;
  border-radius: 0.25rem;
}

.sidebar-item:hover {
  background-color: #f0f0f0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  display: flex;
  margin-bottom: 2rem;
}

i {
  margin-right: 1rem;
}

.link {
  text-decoration: none;
  zoom: 1.2;
  font-weight: 500;
}
</style>
