<script setup lang="ts">
import 'primeicons/primeicons.css';
import { useRoute, useRouter } from 'vue-router';
import Button from 'primevue/button';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import Toast, { type ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import Panel from 'primevue/panel';
import 'primeicons/primeicons.css';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';
import { useSocket } from '@/assets/useSocket';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import { type DraggableEvent, VueDraggable } from 'vue-draggable-plus';

interface Step {
  id: number;
  Step: number;
  Title: string;
  Text: string;
  Picture: string;
  MonitorNumber?: number;
}

interface Program {
  id: number;
  TouchProgramId: number;
  ProgramName: string;
}

interface Recipe {
  id: number;
  title: string;
  subname: string;
  steps: Step[];
  programm: Program;
}

const { socket } = useSocket();
const toast = useToast();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const recipe = ref<Recipe | null>(null);
const deleteDialog = ref<boolean>(false);
const selectedStep = ref();

const recipeName = ref<string>('');

const selectedProgram = ref();
const programs = ref([]);

const steps = ref<Step[]>([]);

//loading spinners
const stepSpinner = ref<boolean>(false);

const onStart = (e: DraggableEvent) => {
  e.preventDefault();
};

const onEnd = (e: DraggableEvent) => {
  swapSteps(e);
};

function swapSteps(event) {
  const movedIndex = event.newIndex;
  const oldIndex = event.oldIndex;

  const tempStep = steps.value[movedIndex].Step;
  steps.value[movedIndex].Step = steps.value[oldIndex].Step;
  steps.value[oldIndex].Step = tempStep;

  saveRecipeOrder();
}

const saveRecipeOrder = async () => {
  try {
    const recipeId = route.params.recipeId;

    const stepsToUpdate = steps.value.map((step, index) => ({
      id: step.id,
      order: index + 1
    }));

    const res = await axios.patch(`/api/recipe/${recipeId}/updateStepsOrder`, {
      steps: stepsToUpdate
    });

    if (res.status === 200) {
      showToast(
        'success',
        t('toastCallback.success'),
        t('admin.dashboard.changedRecipeOrder')
      );
    }
  } catch (error) {
    console.error(t('admin.dashboard.errors.errorSaveOrder'), error);
    showToast(
      'success',
      t('toastCallback.danger'),
      t('admin.dashboard.cannotChangeRecipeOrder')
    );
  }
};

onMounted(async () => {
  getPrograms();
  socket?.value?.on('recipeUpdated', () => {
    getSteps();
  });

  await getSteps();
});

async function getSteps() {
  stepSpinner.value = true;
  try {
    const recipeId = route.params.recipeId;
    const res = await axios.get(`/api/recipe/${recipeId}`);
    if (res.status === 200) {
      recipe.value = res.data as Recipe;
      steps.value = recipe.value.steps;
      selectedProgram.value = res.data.program;
      stepSpinner.value = false;
    } else {
      showToast('error', 'Error', t('admin.dashboard.errors.errorRecipe'));
    }

    recipeName.value = recipe?.value.title;
  } catch (e) {
    console.log(e);
  }
}

async function getPrograms() {
  const res = await axios.get('/api/touch/programs');
  programs.value = res.data;
}

const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 3000
  });
};

const currentParam = route.params.recipeId;

function editStep(i: number) {
  const id = recipe.value?.steps[i].id;
  router.push({
    name: 'editStep',
    params: { recipeId: `${currentParam}`, stepId: `${id}` }
  });
}

function newStep() {
  router.push({ name: 'step', params: { recipeId: `${currentParam}` } });
}

function openDeleteDialog(i: number) {
  deleteDialog.value = true;
  selectedStep.value = recipe.value?.steps[i];
}

function closeDeleteDialog() {
  selectedStep.value = null;
  deleteDialog.value = false;
}

async function deleteStep() {
  const recipeId = route.params.recipeId;
  const stepId = selectedStep.value.id;
  await axios
    .delete(`/api/recipe/${recipeId}/steps/${stepId}`)
    .then(async (res) => {
      if (res.status === 200) {
        const res = await axios.get(`/api/recipe/${recipeId}`);
        recipe.value = res.data;
        showToast('success', 'Success', t('admin.step.success.delete'));
        deleteDialog.value = false;
      }
    })
    .catch((e) => {
      if (e) {
        deleteDialog.value = false;
        showToast('error', 'Error', t('admin.dashboard.errors.deleteError'));
      }
    });
}

const updateRecipeName = async () => {
  const recipeId = route.params.recipeId;
  await axios
    .patch(`/api/recipe/${recipeId}`, {
      title: recipeName.value
    })
    .then(async () => {
      await getSteps();
      showToast(
        'success',
        'Success',
        t('admin.useradministration.editRecipeNameSuccessful')
      );
    })
    .catch(() => {
      showToast(
        'error',
        'Error',
        t('admin.monitorConfiguration.errors.errorEditRecipeName')
      );
    });
};

const onSelectChange = async (event) => {
  const selectedOption = event.value;
  const recipeId = route.params.recipeId;

  await axios
    .patch(`/api/recipe/${recipeId}`, {
      programId: selectedOption.id
    })
    .then(() => {
      showToast(
        'success',
        'Success',
        t('admin.dashboard.successprogramupdate')
      );
    })
    .catch(() => {
      showToast(
        'error',
        'Error',
        t('admin.monitorConfiguration.errors.errorUpdateProgram')
      );
    });
};
</script>

<template>
  <div class="recipe">
    <Toast></Toast>
    <div class="content">
      <div class="back">
        <router-link to="/">
          <Button
            icon="pi pi-arrow-left"
            text
            raised
            rounded
            aria-label="Filter"
            severity="secondary"
          />
        </router-link>
      </div>
      <div class="recipe-picture">
        <div class="action-box">
          <div class="action-box-content">
            <div class="item-1">
              <h5>{{ t('admin.dashboard.recipeName') }}</h5>
              <InputText
                @keyup.enter="updateRecipeName"
                type="text"
                v-model="recipeName"
                size="large"
              />
            </div>
            <div class="item-2">
              <h6>{{ t('admin.dashboard.recipeProgramm') }}</h6>
              <Select
                v-model="selectedProgram"
                :options="programs"
                optionLabel="ProgramName"
                :placeholder="t('admin.dashboard.programmSelect')"
                class="w-full md:w-56"
                @change="onSelectChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="steps">
        <div v-if="stepSpinner" class="spinner">
          <ProgressSpinner :style="{ color: 'blue' }" />
        </div>
        <VueDraggable
          v-model="steps"
          :animation="150"
          class="draggable"
          @start="onStart"
          @end="onEnd"
        >
          <Panel
            class="panel"
            v-for="(step, index) in steps"
            :key="step.id"
            toggleable
            :collapsed="steps.length > 2"
          >
            <template #header>
              <div class="panel-title">
                <Button
                  outlined
                  severity="contrast"
                  :label="`${step.MonitorNumber}`"
                  icon="pi pi-desktop"
                />
                <Button
                  outlined
                  severity="contrast"
                  :label="`${step.Step}`"
                  icon="pi pi-eye"
                />
                <span
                  ><strong>{{ step.Title }}</strong></span
                >
              </div>
            </template>
            <div class="panel-content">
              <div class="file">
                <img :src="step.Picture" alt="Step-picture" />
              </div>
              <div class="instruction">
                <span>{{ t('admin.step.title') }}</span>
                <InputText :placeholder="step.Title" :disabled="true" />
                <span>{{ t('admin.step.instruction') }}</span>
                <div v-html="step.Text" class="formatted-text"></div>
              </div>
            </div>
            <template #footer>
              <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="flex items-center gap-2"></div>
              </div>
            </template>
            <template #icons>
              <Button
                icon="pi pi-pen-to-square"
                severity="secondary"
                rounded
                text
                @click="editStep(index)"
              />
              <Button
                @click="openDeleteDialog(index)"
                icon="pi pi-trash"
                severity="danger"
                rounded
                text
              />
            </template>
          </Panel>
        </VueDraggable>
      </div>
      <div class="footer-buttons">
        <Button :label="t('admin.step.addStep')" @click="newStep()" />
      </div>
    </div>
    <Dialog
      v-model:visible="deleteDialog"
      :header="t('admin.step.deleteStepPopup')"
      :style="{ width: '25rem' }"
      :closable="false"
    >
      <template #footer>
        <Button
          :label="t('admin.monitorConfiguration.deletePopupCancel')"
          text
          severity="secondary"
          @click="closeDeleteDialog"
          autofocus
        />
        <Button
          :label="t('admin.monitorConfiguration.deletePopupSave')"
          @click="deleteStep()"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.recipe {
  height: 100%;
  margin-bottom: 1rem;
}

.panel {
  cursor: grab;
}

#delete-icon {
  color: var(--danger-color);
}

.draggable {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.panel-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.back {
  padding-top: 1rem;
}

.panel-content {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

.file {
  width: 40%;
  height: 50%;
}

.file img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.instruction {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 2rem;
}

.steps {
  display: flex;
  border-radius: 1rem;
  flex-direction: column;
  gap: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 2rem;
}

.recipe-picture {
  position: relative;
  width: 100%;
  height: 25rem;
  border-radius: 1rem;
  background-image: url(../assets/pictures/recipe.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.action-box-content {
  margin-left: 2rem;

  h5,
  h6 {
    padding: 0;
    margin: 2rem 0 0;
  }

  .item-1,
  .item-2 {
    display: grid;
    grid-template-rows: 0fr 0.25fr;
    width: 75%;
    gap: 0;
    margin: 0;
  }
}
.action-box {
  background-color: var(--gray-color);
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-left: 2rem;
  height: 65%;
  width: 30rem;
}

.formatted-text {
  border: 1px solid var(--gray-color);
  border-radius: 1rem;
  padding: 10px;
  min-height: 100px;
  white-space: pre-wrap;
  overflow: auto;
  color: #64748b;
  background: #e2e8f0;
}
</style>
