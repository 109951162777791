export default {
  toastCallback: {
    success: 'Erfolgreich',
    warn: 'Warnung',
    danger: 'Fehler',
    info: 'Info'
  },
  login: {
    signIn: 'Anmelden',
    login: 'Anmelden',
    username: 'Nutzername',
    password: 'Passwort',
    errors: {
      emptyInputs: 'Felder sollten nicht leer sein',
      incorrect: 'Falscher Benutzername oder Passwort'
    }
  },
  admin: {
    modalPassword: {
      changePassword: 'Passwort ändern',
      newPassword: 'Neues Passwort',
      repeatPassword: 'Passwort wiederholen',
      errors: {
        notMatched: 'Passwörter stimmen nicht überein',
        empty: 'Die Felder müssen ausgefüllt sein'
      }
    },
    dashboard: {
      name: 'Name',
      machineName: 'Maschinen-Name',
      recipeName: 'Rezept-Name',
      recipeProgramm: 'Rezept-Programm',
      recipeSubname: 'Rezept-Untertitel',
      newRecipe: 'Neues Rezept',
      recipeOverview: 'Rezeptübersicht',
      recipe: 'Rezept',
      status: 'Status',
      recipeProvider: 'Rezeptegeber',
      action: 'Aktionen',
      upload: 'Hochladen',
      download: 'Herunterladen',
      changePicture: 'ändern',
      manageUsers: 'Nutzer-Verwalten',
      pictureBib: 'Foto-Bibliothek',
      choose: 'Auswählen',
      changeMachineName: 'Maschinenname ändern',
      savedRecipe: 'Rezept Gespeichert!',
      changedRecipeOrder: 'Rezept Reihenfolge geändert!',
      maxStepsReached:
        'Maximale Anzahl von 5 Schritten mit diesem Monitor erreicht.',
      cannotChangeRecipeOrder:
        'Rezept reihenfolge konnte nicht geändert werden!',
      sortLetters: 'Buchstaben',
      sortDate: 'Datum',
      inactive: 'inaktiv',
      active: 'aktiv',
      search: 'Suchen',
      uploadRecipe: 'Rezept hochladen',
      createUserSuccsesful: 'Nutzer wurde erfolgreich erstellt',
      programmSelect: 'Wählen Sie ein Programm',
      monitor: 'Monitor',
      successprogramupdate: 'Programm erfolgreich aktualisiert',
      noProgram: 'Kein Programm hinterlegt!',
      errors: {
        newRecipe: 'Rezept Titel muss gegeben sein!',
        searchError: 'Fehler beim suchen',
        roleError: 'Rollen konnten nicht geladen werden',
        noRecipe: 'Es wurde kein Rezept ausgewählt!',
        deleteError: 'Fehler beim löschen des Rezepts',
        errorRecipe: 'Rezept konnte nicht geladen werden',
        errorCreateUser: 'Fehler beim erstellen des Nutzers',
        errorSaveOrder: 'Fehler beim Speichern der Rezeptreihenfolge:',
        errorLoadRecipe: 'Rezept/e konnte/n nicht geladen werden'
      }
    },
    monitorConfiguration: {
      monitorConfig: 'Monitorverwaltung',
      description: 'Monitore Verwalten',
      monitors: 'Monitore',
      notAdded: 'nicht zugewiesen',
      archived: 'im Archiv',
      notFound: 'Leider wurde kein Monitor gefunden.',
      serialNumber: 'Seriennummer',
      details: 'Details',
      since: 'seit',
      known: 'bekannt',
      added: 'zugewiesen',
      monitor: 'Zuweisung',
      chooseMonitor: 'Wähle einen Monitor',
      expertModeEnabled: 'Der Experten-Modus wurde aktiviert!',
      expertModeDisabled: 'Der Experten-Modus wurde deaktiviert!',
      expert: 'Expertenmodus',
      maintenance: 'Monitorwartung',
      delete: 'Monitor löschen',
      deletedMonitor: 'Monitor erfolgreich gelöscht!',
      confirmMonitor: 'Monitor erfolgreich bestätigt!',
      setMaintenance: 'Monitor erfolgreich in Wartungsmodus gesetzt!',
      deactivateMaintenance: 'Monitor erfolgreich in Normalbetrieb gesetzt!',
      addedSuccessfully: 'Monitor erfolgreich zugewiesen!',
      preview: 'Vorschau öffnen',
      previewButton: 'Vorschau',
      deleteButton: 'Löschen',
      maintenanceButton: 'Wartung',
      deletePopup: 'Monitor wirklich Löschen?',
      deletePopupSave: 'Speichern',
      deletePopupCancel: 'Abbrechen',
      confirmButton: 'Bestätigen',
      confirmButtonTooltip: 'Monitor bestätigen',
      userDeletePopup: 'Nutzer wirklich Löschen?',
      userDelete: 'Nutzer löschen',
      userEdit: 'Nutzer bearbeiten',
      createUser: 'Nutzer erstellen',
      selectRole: 'Rolle zuweisen',
      generatePassword: 'Passwort generieren',
      latestFilter: 'Neustes',
      oldestFilter: 'Ältestes',
      setRecipeActive: 'Rezept aktiv gesetzt!',
      setRecipeInactive: 'Rezept inaktiv gesetzt!',
      recipeUploadSuccessfull: 'Rezept erfolgreich hochgeladen!',
      successDelete: 'Rezept erfolgreich gelöscht',
      recipeDeletePopup: 'Das Rezept wirklich löschen?',
      errors: {
        errorConfirmingMonitor: 'Fehler beim bestätigen des Monitors',
        errorEditRecipeName: 'Fehler beim bearbeiten des Rezeptnamen',
        errorUpdateProgram: 'Fehler beim updaten des Programms',
        errorPasswordUpdate: 'Passwort konnte nicht aktualisiert werden',
        errorRecipeStatus: 'Konnte Rezeptstatus nicht ändern!',
        recipeUploadError: 'Rezept kann nicht hochgeladen werden',
        addedFailed: 'Konnte Monitor nicht zuweisen!',
        cannotDeleteMonitor: 'Konnte Monitor nicht löschen!',
        cannotPutInMaintenance: 'Konnte den Wartungsmodus nicht ändern'
      }
    },
    step: {
      createStep: 'Schritt erstellen',
      addStep: 'Schritt hinzufügen',
      editStep: 'Schritt bearbeiten',
      step: 'Schritt',
      title: 'Titel',
      instruction: 'Anweisung',
      saveStep: 'Schritt speichern',
      draganddrop: 'Dateien zum Hochladen hier ablegen, oder Gerät durchsuchen',
      importantNote: 'Wichtiger Hinweis',
      deleteStepPopup: 'Schritt wirklich löschen?',
      errors: {
        createStep: 'Fehler beim erstellen des Schritts',
        emptyFields: 'Felder sind nicht gefüllt',
        editStep: 'Fehler beim bearbeiten des Schritts',
        monitorOptions: 'Keine Option gefunden für Monitor:'
      },
      success: {
        created: 'Schritt wurde erfolgreich erstellt',
        updated: 'Schritt wurde erfolgreich bearbeitet',
        delete: 'Schritt wurde erfolgreich gelöscht'
      }
    },
    administration: {
      title: 'Administration',
      description: 'Dies ist die Administrationsübersicht.',
      open: 'Öffnen'
    },
    useradministration: {
      title: 'Nutzerverwaltung',
      description: 'User Verwalten',
      registeredUsers: 'Registrierte Nutzer',
      user: 'Nutzer',
      addUser: 'Nutzer anlegen',
      lastLogin: 'Letzter Login',
      actions: 'Aktionen',
      editRecipeNameSuccessful: 'Rezeptname erfolgreich geändert',
      infos: {
        emptyFields: 'Felder nicht gefüllt',
        editUserSuccessful: 'Nutzer erfolgreich bearbeitet',
        userDeleted: 'Nutzer gelöscht',
        editUser: 'Nutzer geändert',
        noPermission: 'Keine Berechtigung'
      },
      errors: {
        errorEditUser: 'Nutzer konnte nicht bearbeitet werden',
        errorDeleteUser: 'Nutzer konnte nicht gelöscht werden',
        errorPermission: 'Sie haben keine Berechtigung',
        error: 'Etwas ist schiefgelaufen',
        userExist: 'Ein Nutzer mit den Name existiert bereits'
      }
    },
    navebarComponent: {
      language: 'Sprache',
      english: 'Englisch',
      german: 'Deutsch'
    },
    sidebarComponent: {
      dashbaord: 'Dashboard',
      administraion: 'Administration',
      monitor: 'Monitor'
    }
  }
};
