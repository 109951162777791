<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { useSocket } from '@/assets/useSocket';
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import axios, { type AxiosResponse } from 'axios';
import moment from 'moment';
import ProgressSpinner from 'primevue/progressspinner';
import type { ToastMessageOptions } from 'primevue/toast';

const { socket } = useSocket();
const toast = useToast();
const { t } = useI18n();

const screenshot = ref<boolean>(false);
const monitorInformation = ref<MonitorType[]>([]);
const service = ref<string>();
const requireService = ref<boolean>();
const showLogs = ref<boolean>();
const ipAddress = ref<string>();
const visible = ref<boolean>(false);
const monitors = ref<number>(0);
const monitorResponse = ref();

//* loading spinners
const monitorSpinner = ref<boolean>(false);

type ItemType = {
  IPAddress: string;
  MonitorSerialNumber: string;
  relatedSince?: Date;
  updatedAt?: Date;
  createdAt?: Date;
  Monitor: number;
  Active: boolean;
  Confirmed: boolean;
};

type MonitorType = {
  serialNumber: string;
  IPAddress: string;
  related?: number;
  knownSince?: Date;
  addedSince?: Date;
  confirmed?: boolean;
};

const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 1500
  });
};

//* händelt die Monitorinformationen (aktiv / zugewiesen / nicht zugewiesen ...)
async function setupInformation() {
  monitorSpinner.value = true;
  monitorInformation.value = [];
  const response: AxiosResponse = await axios.get('/api/monitors/all');
  if (response.data) {
    response.data.forEach((item: ItemType) => {
      monitorInformation.value.push({
        serialNumber: item.MonitorSerialNumber,
        IPAddress: item.IPAddress,
        related: item.Monitor,
        knownSince: item?.createdAt,
        addedSince: item.relatedSince,
        confirmed: item?.Confirmed
      });
    });
  }

  monitors.value = monitorInformation.value.length;
  monitorSpinner.value = false;
}

const formatDate = (dateString: Date) => {
  return moment(dateString).format('DD.MM.YYYY HH:mm');
};

onMounted(async () => {
  socket?.value?.on('monitorUpdated', async () => {
    await setupInformation();
  });

  await setupInformation();
});

function restartSystem(ip: string) {
  axios
    .post(
      'http://192.168.100.128:8080/api/reboot',
      {},
      {
        auth: {
          username: 'admin',
          password: 'krumbein'
        }
      }
    )
    .then((response) => {
      showToast(
        'success',
        'Das hat geklappt!',
        'Der Monitor wird nun neugestartet!'
      );

      console.log('Antwort:', response.data);
    })
    .catch((error) => {
      console.error(
        'Fehler:',
        error.response ? error.response.data : error.message
      );
      showToast('error', 'Ein Fehler!', error.message);
    });
}

function restartService(ip: string) {
  monitorResponse.value = '';
  screenshot.value = false;
  ipAddress.value = ip;
  visible.value = true;
  requireService.value = true;
}

function getLogs(ip: string) {
  monitorResponse.value = '';
  screenshot.value = false;
  ipAddress.value = ip;
  visible.value = true;
  showLogs.value = true;
  requireService.value = true;
}

//* handles requests that require a service
function handleServices() {
  visible.value = false;
  if (!showLogs.value) {
    axios
      .post(
        'http://192.168.100.128:8080/api/restartservice',
        { serviceName: service.value },
        {
          auth: {
            username: 'admin',
            password: 'krumbein'
          }
        }
      )
      .then((response) => {
        showToast(
          'success',
          'Das hat geklappt!',
          'Der Service wird nun neugestartet!'
        );

        console.log('Antwort:', response.data);
        monitorResponse.value = response.data;
      })
      .catch((error) => {
        console.error(
          'Fehler:',
          error.response ? error.response.data : error.message
        );
        showToast('error', 'Ein Fehler!', error.message);
      });
  } else {
    axios
      .post(
        `http://192.168.100.128:8080/api/servicelogs/${service.value}`,
        {},
        {
          auth: {
            username: 'admin',
            password: 'krumbein'
          }
        }
      )
      .then((response) => {
        screenshot.value = false;
        showToast('success', 'Das hat geklappt!', '');
        console.log('Antwort:', response.data);
        showLogs.value = false;
        visible.value = true;
        monitorResponse.value = response.data;
      })
      .catch((error) => {
        console.error(
          'Fehler:',
          error.response ? error.response.data : error.message
        );
        showToast('error', 'Ein Fehler!', error.message);
      });
  }
  requireService.value = false;
  service.value = '';
}

function getScreenshot() {
  axios
    .get('http://192.168.100.128:8080/api/screenshot', {
      auth: { username: 'admin', password: 'krumbein' },
      responseType: 'blob'
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);

      showToast('success', 'Das hat geklappt!', '');

      screenshot.value = true;
      console.log('Antwort:', response.data);
      visible.value = true;
      monitorResponse.value = url;
    })
    .catch((error) => {
      console.error(
        'Fehler:',
        error.response ? error.response.data : error.message
      );
      showToast('error', 'Ein Fehler!', error.message);
    });
}

function getData(path: string) {
  axios
    .get(`http://192.168.100.128:8080/api/${path}`, {
      auth: {
        username: 'admin',
        password: 'krumbein'
      }
    })
    .then((response) => {
      showToast('success', 'Das hat geklappt!', '');

      screenshot.value = false;
      console.log('Antwort:', response.data);
      visible.value = true;
      monitorResponse.value = response.data;
    })
    .catch((error) => {
      console.error(
        'Fehler:',
        error.response ? error.response.data : error.message
      );
      showToast('error', 'Ein Fehler!', error.message);
    });
}
</script>

<template>
  <section class="content newScrollbar">
    <Dialog
      v-model:visible="visible"
      modal
      header="Systemsteuerung"
      :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      @close="
        visible = false;
        requireService = false;
      "
    >
      <div class="serviceInput" v-if="requireService">
        <div class="input">
          <p>{{ showLogs ? 'Log Service' : 'Dienst neustarten' }}</p>
          <InputText placeholder="Service" type="text" v-model="service" />
        </div>
        <div class="buttons">
          <Button severity="success" label="Ok" @click="handleServices" />
          <Button
            severity="danger"
            label="Abbrechen"
            @click="
              visible = false;
              requireService = false;
            "
          />
        </div>
      </div>
      <div class="response-section">
        <h5 class="m-0">Response:</h5>
        <p v-if="!screenshot">{{ monitorResponse }}</p>
        <img v-else :src="monitorResponse" alt="Screenshot" />
      </div>
    </Dialog>

    <div class="back">
      <router-link to="/administration">
        <Button
          icon="pi pi-arrow-left"
          text
          raised
          rounded
          aria-label="Filter"
          severity="secondary"
        />
      </router-link>
    </div>
    <div class="monitor-picture">
      <div class="action-box">
        <div class="action-box-content">
          <div class="item-1">
            <h3>Systemsteuerung</h3>
            <p>Dies ist die Systemsteuerung der Monitore</p>
            <router-link to="/monitor/configuration">
              <Button
                icon="pi pi-monitor"
                label="zur Monitorverwaltung"
                raised
                aria-label="Filter"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="monitorSpinner" class="spinner">
      <ProgressSpinner :style="{ color: 'blue' }" />
    </div>

    <div class="no-monitor-found" v-if="!monitors">
      <h1>
        {{ t('admin.monitorConfiguration.notFound') }}
      </h1>
    </div>

    <div class="monitors-content" v-else>
      <h6 style="margin: 0">Monitore</h6>
      <div
        class="monitor-found"
        v-for="monitor in monitorInformation"
        :key="monitor.serialNumber"
      >
        <div class="monitor-name">
          <h5>{{ t('admin.monitorConfiguration.serialNumber') }}</h5>
          <InputText
            id="monitorName"
            type="text"
            v-model="monitor.serialNumber"
            size="large"
            disabled
          />
        </div>
        <div class="details">
          <h5>{{ t('admin.monitorConfiguration.details') }}</h5>
          <ul>
            <li>
              {{ t('admin.monitorConfiguration.since') }}
              {{ formatDate(monitor.knownSince) }}
              {{ t('admin.monitorConfiguration.known') }}
            </li>
            <li v-if="monitor.addedSince">
              {{ t('admin.monitorConfiguration.since') }}
              {{ formatDate(monitor.addedSince) }}
              {{ t('admin.monitorConfiguration.added') }}
            </li>
            <li v-else>
              {{ t('admin.monitorConfiguration.notAdded') }}
            </li>
            <li>
              IP:
              {{ monitor.IPAddress }}
            </li>
            <li v-if="monitor.related">
              Zuweisung: Monitor {{ monitor.related }}
            </li>
          </ul>
        </div>
        <div class="buttons">
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="restartSystem(monitor.IPAddress)"
            label="System neu starten"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="restartService(monitor.IPAddress)"
            label="Dienst neu starten"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getLogs(monitor.IPAddress)"
            label="Logs abrufen"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getData('macaddresses')"
            label="MAC-Adressen anzeigen"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getScreenshot"
            label="Screenshot erstellen"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getData('cpu')"
            label="CPU-Auslastung anzeigen"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getData('memory')"
            label="Arbeitsspeicher anzeigen"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getData('disk')"
            label="Festplattenspeicher anzeigen"
          />
          <Button
            v-tooltip="{
              value: `${t('admin.monitorConfiguration.preview')}`,
              showDelay: 250,
              hideDelay: 0
            }"
            @click="getData('sysinfo')"
            label="Systeminformationen anzeigen"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  height: calc(100vh - 10.75rem);
}

#monitorName {
  width: 100%;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes pulse {
  0% {
    border: 3px solid var(--warning-color);
  }
  50% {
    border: 3px solid var(--danger-color);
  }
  100% {
    border: 3px solid var(--warning-color);
  }
}

.monitor-picture {
  position: relative;
  width: 100%;
  height: 21rem;
  border-radius: 1rem;
  background-image: url(@/assets/pictures/banner-monitor-configuration.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 1rem;
}

.action-box-content {
  position: relative;
  margin-left: 2rem;
  width: calc(100% - 2rem);

  h3 {
    padding: 0;
    margin: 2rem 0 0;
  }

  .item-1 {
    display: grid;
    grid-template-rows: 0fr 0.25fr;
    width: 75%;
    gap: 0;
    margin: 0;
  }
}
.action-box {
  background-color: var(--gray-color);
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-left: 2rem;
  height: 10rem;
  width: 30rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 2rem;
}

.monitor-found {
  grid-template-columns: 1fr 0.7fr 1fr 0.1fr;
  gap: 1.5rem;
}

.monitor-found {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  display: grid;
  width: 100%;
  background: var(--gray-color);
  border-radius: 1rem;

  h5,
  h4 {
    padding: 0;
    margin: 0 0 1rem;
  }

  .monitor-name {
    display: flex;
    flex-direction: column;
  }

  .details {
    display: block;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.25rem;
  }
}

.no-monitor-found {
  display: flex;
  justify-content: center;
}

.monitors-content {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}

.back {
  padding-top: 1rem;
}

.response-section {
  display: flex;
  justify-content: center;
  flex-direction: column;

  h5,
  p {
    margin: 0;
    padding: 0;
  }
}

.serviceInput {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .input p {
    margin: 0;
    padding: 0;
  }

  .buttons {
    padding: 1rem;
    display: flex;
    gap: 1rem;
  }
}
</style>
